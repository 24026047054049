exports.components = {
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/homepage-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-homepage-query-tsx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-kontakt-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/pages/kontakt/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-kontakt-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-o-nas-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/page-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/pages/o-nas/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-page-query-tsx-content-file-path-content-pages-o-nas-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-cyfrowe-abc-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/projekty/cyfrowe-abc/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-cyfrowe-abc-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-darmowe-ebooki-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/projekty/darmowe-ebooki/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-darmowe-ebooki-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-pomocna-dlon-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/projekty/pomocna-dlon/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-pomocna-dlon-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-przechytrz-wnuczka-index-mdx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/project-query.tsx?__contentFilePath=/home/runner/work/rowniwsieci.pl/rowniwsieci.pl/content/projekty/przechytrz-wnuczka/index.mdx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-project-query-tsx-content-file-path-content-projekty-przechytrz-wnuczka-index-mdx" */),
  "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx": () => import("./../../../node_modules/@lekoarts/gatsby-theme-jodie-core/src/templates/projects-query.tsx" /* webpackChunkName: "component---node-modules-lekoarts-gatsby-theme-jodie-core-src-templates-projects-query-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */)
}

